import { useState } from 'react';
import './App.scss';
import BricksRow from './components/BricksRow';
import dummy from './dummy.json';

function App() {
  const [rows, setRows] = useState([])

  return (
    <main className='main-wrapper'>
      <div className='top-toolbar'>
        <button onClick={() => {setRows([])}}>Home</button>
      </div>
      {
        rows.length === 0 ?
          dummy.filter(row => !row.parentId).map(el => {
            return (
              <div 
                className='row-card'
                key={el.id}
                onClick={() => setRows([...rows, dummy.filter(f => f.parentId === el.id)])}
              >
                {el.header}
              </div>
            )
          }) : ''
      }
      {
        rows.length > 0 
          ? rows.map(row => <BricksRow key={`brick-row-${row.id}`} childs={row} />)
          : ''
      }
    </main>
  );
}

export default App;
