const handleRead = (id) => {
  console.log('Read ', id)
}
const handleWrite = (id) => { console.log('Write ', id) }

const Brick = ({brick}) => {
  return (
    <div className="brick">
      <label>{brick.header}</label>
      <br />
      <p>{brick.content}</p>
      <hr />
      <div className="brick-actions">
        <span onClick={() => handleRead(brick.id)}>Read</span>
        <span onClick={() => handleWrite(brick.id)}>Write</span>
      </div>
    </div>
  )
}

export default Brick