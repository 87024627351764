import Brick from "./Brick"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const BricksRow = ({childs}) => {
  console.log('childs', childs)
  return (
    <div className='bricks-wrapper'>
      <Swiper
        spaceBetween={50}
        slidesPerView={3}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {
          childs.map(cnt => {
            return (
              <SwiperSlide key={`brick-${cnt.parentId}-${cnt.id}`}>
                <Brick brick={cnt} />
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </div>
  )
}

export default BricksRow